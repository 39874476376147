import { groupBy, sum } from '../../../utils/arrayUtils';
import { translateNameI18n } from '../../../utils/i18nUtils';
import { reactive, computed, watch } from 'vue';
import { getAllUrlParams, getLocaleFromUrl } from '../../../utils/windowUtils';
import { useAssortmentMailSummary } from './assortmentSummary';
import { useAssortmentNetwork } from './assortmentNetwork';
import { useAssortmentIp } from './assortmentIp';

export function useAssortmentColocation(
    assortmentContent,
    assortmentNetworkContent,
    assortmentIpContent
) {
    const { trafficAssortment } = useAssortmentNetwork(assortmentNetworkContent);
    const { ipV4AddressAssortment, ipV4NetworkAssortment, ipV6NetworkAssortment } =
        useAssortmentIp(assortmentIpContent);

    const fields = reactive({
        rackId: null,
        feedId: null,
        uplinkId: null,
        rackQuantity: null,
        power: 0,

        volumeId: 1,
        ipv4Addresses: 1,
        ipv4Subnet: null, // TODO: change to ipv4SubnetId when all configurators are updated
        ipv6Subnet: null, // TODO: change to ipv6SubnetId when all configurators are updated
    });

    const assortment = reactive({
        racks: [],
        racksGrouped: [],

        volumes: [],
        ipv4Address: {},
        ipv4Subnets: [],
        ipv6Subnets: [],
    });

    const selection = computed(() => {
        const rack = assortment.racks.find((rack) => String(rack.id) === String(fields.rackId));

        return {
            rack: rack,
            feed: rack?.feeds.find((feed) => String(feed.id) === String(fields.feedId)),
            uplink: rack?.uplinks.find((uplink) => String(uplink.id) === String(fields.uplinkId)),
            rackQuantity: fields.rackQuantity,
            power: fields.power,

            volume: assortment.volumes[fields.volumeId],
            ipv4Addresses: fields.ipv4Addresses,
            ipv4Subnet: assortment.ipv4Subnets[fields.ipv4Subnet] || null,
            ipv6Subnet: assortment.ipv6Subnets[fields.ipv6Subnet] || null,
        };
    });

    const powerMin = computed(() => {
        const quantity = selection.value.rack.max_quantity ? fields.rackQuantity : 1;
        return selection.value.rack.power.watts_min * quantity;
    });

    const fuseProtection = computed(() => {
        return selection.value.rack?.fuse_protection?.name || '';
    });

    const numberOfPayedIpv4Addresses = computed(() =>
        Math.max(...[0, selection.value.ipv4Addresses - assortment.ipv4Address.free])
    );

    const monthlyPrices = computed(() => {
        const prices = {};

        const rack = selection.value.rack;
        prices.rack = rack.max_quantity
            ? rack.price.monthly_price * selection.value.rackQuantity
            : rack.price.monthly_price;

        prices.feed = selection.value.feed?.monthly_price ?? 0;
        prices.power = selection.value.power * selection.value.rack.power.price_per_watt;
        prices.uplink = selection.value.uplink.monthly_price;
        prices.volume = selection.value.volume?.monthly_price ?? 0;

        prices.ipv4Addresses = numberOfPayedIpv4Addresses.value * assortment.ipv4Address.price;
        prices.ipv4Subnet = selection.value.ipv4Subnet?.monthly_price ?? 0;
        prices.ipv6Subnet = selection.value.ipv6Subnet?.monthly_price ?? 0;

        return prices;
    });

    const monthlyPrice = computed(() => {
        return sum(Object.values(monthlyPrices.value));
    });

    const setupPrices = computed(() => {
        const prices = {};
        prices.rack = selection.value.rack.price.setup_price;

        prices.ipv4Addresses = numberOfPayedIpv4Addresses.value * assortment.ipv4Address.setupPrice;
        prices.ipv4Subnet = selection.value.ipv4Subnet?.setup_price ?? 0;
        prices.ipv6Subnet = selection.value.ipv6Subnet?.setup_price ?? 0;
        return prices;
    });

    const setupPrice = computed(() => {
        return sum(Object.values(setupPrices.value));
    });

    const initAssortment = (rackAssortment) => {
        assortment.racks = rackAssortment;
        assortment.racksGrouped = groupBy(assortment.racks, (rack) => {
            const currentLocale = getLocaleFromUrl();
            return rack.rack_group_type?.name_i18n[currentLocale];
        });

        assortment.volumes = trafficAssortment;
        assortment.ipv4Address = ipV4AddressAssortment;
        assortment.ipv4Subnets = ipV4NetworkAssortment;
        assortment.ipv6Subnets = ipV6NetworkAssortment;

        translateNameI18n(assortment, 'name');
    };

    const initRackDefaultFields = (rack) => {
        fields.feedId = rack.feeds[0].id;
        fields.uplinkId = rack.uplinks[0].id;
        fields.power = powerMin.value;
        if (rack.can_select_ip_addresses) {
            fields.ipv4Addresses = 1;
        } else {
            fields.ipv4Addresses = 0;
        }
    };

    const initFields = (rack) => {
        fields.rackId = rack.id;
        fields.rackQuantity = rack.max_quantity ? 1 : null;
        initRackDefaultFields(rack);
    };

    const initFieldsByUrl = () => {
        const urlParams = getAllUrlParams();
        if (urlParams.rack) {
            fields.rackId = parseInt(urlParams.rack);
            const rack = assortment.racks.find((rack) => String(rack.id) === String(fields.rackId));
            initRackDefaultFields(rack);
        }
        if (urlParams.feed) {
            fields.feedId = urlParams.feed;
        }
        if (urlParams.uplink) {
            fields.uplinkId = parseInt(urlParams.uplink);
        }
        if (urlParams.rackQuantity) {
            fields.rackQuantity = parseInt(urlParams.rackQuantity);
        }
        if (urlParams.power) {
            fields.power = parseInt(urlParams.power);
        }
        if (urlParams.volume) {
            fields.volumeId = parseInt(urlParams.volume);
        }

        if (urlParams.ipv4Addresses) {
            fields.ipv4Addresses = parseInt(urlParams.ipv4Addresses);
            fields.ipv4Subnet = null;
            fields.ipv6Subnet = null;
        }
        if (urlParams.ipv4Subnet) {
            fields.ipv4Subnet = parseInt(urlParams.ipv4Subnet);
            fields.ipv4Addresses = 0;
        }
        if (urlParams.ipv6Subnet) {
            fields.ipv6Subnet = parseInt(urlParams.ipv6Subnet);
            fields.ipv4Addresses = 0;
        }
    };

    const mailSummary = computed(() => {
        const summaries = {};
        summaries.rack = selection.value.rack?.max_quantity
            ? `Basis: ${selection.value.rackQuantity} HE`
            : `Basis: ${selection.value.rack?.name}`;
        summaries.power = `Strom: ${selection.value.feed?.name} mit ${selection.value.power} W`;

        const { networkMailSummary, ipMailSummary } = useAssortmentMailSummary(selection);
        summaries.network = networkMailSummary.value;
        summaries.ip = ipMailSummary.value;

        return Object.values(summaries).join('\n');
    });

    watch(
        () => fields.rackId,
        (newValue, oldValue) => {
            if (oldValue !== null) {
                const rack = assortment.racks.find((rack) => String(rack.id) === String(newValue));
                initRackDefaultFields(rack);
            }
        }
    );

    watch(
        () => fields.rackQuantity,
        (newValue, oldValue) => {
            if (oldValue !== null) {
                fields.power = powerMin.value;
            }
        }
    );

    const rackAssortment = JSON.parse(assortmentContent);

    initAssortment(rackAssortment);
    initFields(rackAssortment[0]);
    initFieldsByUrl();

    return {
        assortment,
        fields,
        selection,
        powerMin,
        fuseProtection,
        numberOfPayedIpv4Addresses,
        monthlyPrices,
        monthlyPrice,
        setupPrices,
        setupPrice,
        mailSummary,
    };
}
