<template>
    <span
        :data-spec-class="
            isPreorder ? 'confirmation-form__submit--preorder' : 'confirmation-form__submit--order'
        "
    >
        {{ buttonText }}
    </span>
</template>

<script setup>
import { AssortmentType } from '../../../types';
import { defineProps, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
    type: {
        required: true,
    },
    selection: {
        type: Object,
        required: true,
    },
    availableFrom: {
        type: String,
        default: null,
    },
});

const isPreorder = computed(
    () =>
        props.type === AssortmentType.LatestServer && props.selection.device.available_from !== null
);

const buttonText = computed(() =>
    isPreorder.value
        ? t('configuratorGeneral.preorderButton')
        : t('configuratorGeneral.orderButton')
);
</script>
