<template>
    <div class="configurator mt-6">
        <div class="rounded bg-gray-700 py-6 text-white">
            <img
                src="/img/icons/23m/dedicated-server.svg"
                alt="Dedicated Server"
                class="mx-auto h-24 w-36"
            />
            <Columns>
                <Column>
                    <ColumnTitle>{{ $t('configuratorDedicated.base') }}</ColumnTitle>
                    <DedicatedSelectionCard :selection="selection"> </DedicatedSelectionCard>
                </Column>

                <Column>
                    <NetworkConfigurationFieldset
                        :uplinks="selection.device.uplinks"
                        :volumes="assortment.volumes"
                        :fields="fields"
                        :selection="selection"
                    />
                    <IpConfigurationFieldset
                        :assortment="assortment"
                        :fields="fields"
                        :selection="selection"
                    />
                </Column>

                <Column>
                    <ColumnTitle>{{ $t('configuratorGeneral.price') }}</ColumnTitle>
                    <PriceBanner
                        :price="monthlyPrice"
                        :setup-price="setupPrice"
                    />
                    <Price
                        :price="monthlyPrice"
                        :setup_price="setupPrice"
                    />
                    <div>
                        <hint>{{ $t('configuratorDedicated.provision') }}</hint>
                        <hint>{{ $t('configuratorDedicated.contractTerm') }}</hint>
                        <hint>{{ $t('configuratorDedicated.noticePeriod') }}</hint>
                    </div>

                    <div class="mt-6 flex w-full justify-center gap-3">
                        <Back
                            href="/dedicated-server"
                            :without-query-params="[
                                'uplinkId',
                                'volumeId',
                                'ipv4Subnet',
                                'ipv6Subnet',
                                'ipv4Addresses',
                            ]"
                            >{{ $t('actions.back') }}</Back
                        >

                        <Confirm @click.prevent="goToNextPage()">{{
                            $t('configuratorGeneral.continue')
                        }}</Confirm>
                    </div>
                </Column>
            </Columns>

            <div class="mx-6 mt-6 lg:mt-9">
                <BaseLine class="border-gray-500" />
            </div>

            <SpecialDealsAd></SpecialDealsAd>
        </div>
    </div>
</template>

<script setup>
import Columns from './Configurator/Columns.vue';
import Column from './Configurator/Column.vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Price from './Configurator/Price.vue';
import PriceBanner from './Configurator/PriceBanner.vue';
import Confirm from './Configurator/Confirm.vue';
import Back from './Configurator/Back.vue';
import Hint from './Configurator/Hint.vue';
import IpConfigurationFieldset from './IpConfigurationFieldset.vue';
import NetworkConfigurationFieldset from './NetworkConfigurationFieldset.vue';
import DedicatedSelectionCard from './DedicatedSelectionCard.vue';

import SpecialDealsAd from './SpecialDealsAd.vue';
import { useAssortmentDedicated } from '../composables/assortmentDedicated';

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const { fields, assortment, monthlyPrice, setupPrice, selection } = useAssortmentDedicated(
    props.assortmentContent,
    props.assortmentNetworkContent,
    props.assortmentIpContent
);

const goToNextPage = () => {
    const url = '/dedicated-server/confirm';
    window.location.href = `${url}${window.location.search}`;
};
</script>
