import _pickBy from 'lodash/pickBy';
import _mapValues from 'lodash/mapValues';

export function mapAttribute(assortment, key, postfix = '') {
    return _mapValues(assortment, (entry) => entry[key] + postfix);
}

export function mapSubnetAttribute(assortment, key, postfix = '') {
    return _mapValues(
        assortment,
        (entry) => `${entry[key] + postfix} (${calculateIpAddresses(entry.size)} IPs)`
    );
}

const calculateIpAddresses = (size) => {
    if (size > 32) {
        return `2^${size}`;
    }
    const countOfNotAvailableAddresses = 2;
    const subnetMask = parseInt(size, 10);
    const hostBits = 32 - subnetMask;
    const numberOfIpAddresses = Math.pow(2, hostBits);

    return numberOfIpAddresses - countOfNotAvailableAddresses;
};
