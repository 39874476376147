<template>
    <div data-spec-class="ip_configuration_fieldset">
        <ColumnTitle class="p-3">
            {{ $t('configuratorGeneral.ipConfiguration') }}
        </ColumnTitle>

        <Dropdown
            :class="[
                'mb-3',
                changeAnimationClass,
                isIpSubnetOverridden ? 'bg-gray-600' : 'bg-gray-700',
            ]"
            v-model="fields.ipv4Subnet"
            name="ipv4Subnet"
            :label="$t('configuratorGeneral.ipv4Subnet')"
            :options="ipv4SubnetList"
            :show-empty-value="true"
            data-spec-class="ip_configuration_fieldset--ipv4Subnet"
        />
        <Dropdown
            :class="[changeAnimationClass, isIpSubnetOverridden ? 'bg-gray-600' : 'bg-gray-700']"
            v-model="fields.ipv6Subnet"
            name="ipv6Subnet"
            :label="$t('configuratorGeneral.ipv6Subnet')"
            :options="ipv6SubnetList"
            :show-empty-value="true"
            data-spec-class="ip_configuration_fieldset--ipv6Subnet"
        />
        <template v-if="canSelectIpAddresses">
            <BaseDivider class="mb-3 mt-4"> {{ $t('configuratorGeneral.or') }} </BaseDivider>
            {{ fields.ipv4Address }}
            <Range
                :class="[
                    changeAnimationClass,
                    isIpAddressesOverridden ? 'bg-gray-600' : 'bg-gray-700',
                ]"
                v-model="fields.ipv4Addresses"
                name="ipv4Addresses"
                :min="assortment.ipv4Address.min"
                :max="assortment.ipv4Address.max"
                :label="$t('configuratorGeneral.ipv4Addresses')"
                data-spec-class="ip_configuration_fieldset--ipv4Address"
            />
        </template>
    </div>
</template>

<script setup>
import _pickBy from 'lodash/pickBy';

import { computed, defineProps, watch, ref } from 'vue';
import { mapSubnetAttribute } from '../assortments/AssortmentManager';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Dropdown from './Configurator/Dropdown.vue';
import Range from './Configurator/Range.vue';
import BaseDivider from '../../../components/BaseDivider.vue';
import { delay } from '../../../utils/timeUtils';

const animationDuration = 300;

const props = defineProps({
    assortment: { type: Object, required: true },
    fields: { type: Object, required: true },
    selection: { type: Object, required: true },
    canSelectIpAddresses: { type: Boolean, default: true },
});

const isIpSubnetOverridden = ref(false);
const isIpAddressesOverridden = ref(false);

const ipv4SubnetList = computed(() => {
    return mapSubnetAttribute(props.assortment.ipv4Subnets, 'name', ' Subnet');
});

const ipv6SubnetList = computed(() => {
    return mapSubnetAttribute(props.assortment.ipv6Subnets, 'name', ' Subnet');
});

const changeAnimationClass = '-m-1.5 rounded p-1.5 transition duration-300 ease-out';

watch(
    () => props.selection.ipv4Subnet,
    (value) => {
        if (value && props.selection.ipv4Addresses > 0) {
            props.fields.ipv4Addresses = 0;
            isIpAddressesOverridden.value = true;
            delay(animationDuration).then(() => {
                isIpAddressesOverridden.value = false;
            });
        }
    }
);

watch(
    () => props.selection.ipv6Subnet,
    (value) => {
        if (value && props.selection.ipv4Addresses > 0) {
            props.fields.ipv4Addresses = 0;
            isIpAddressesOverridden.value = true;
            delay(animationDuration).then(() => {
                isIpAddressesOverridden.value = false;
            });
        }
    }
);

watch(
    () => props.selection.ipv4Addresses,
    (value) => {
        if (value && (props.selection.ipv4Subnet || props.selection.ipv6Subnet)) {
            props.fields.ipv4Subnet = null;
            props.fields.ipv6Subnet = null;
            isIpSubnetOverridden.value = true;
            delay(animationDuration).then(() => {
                isIpSubnetOverridden.value = false;
            });
        }
    }
);
</script>
