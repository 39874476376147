<template>
    <label>
        <input
            @input="$emit('update:modelValue', id)"
            :value="modelValue"
            :name="name"
            type="radio"
            class="item hidden"
        />
        <div
            class="cursor-pointer space-y-2 whitespace-nowrap rounded border border-gray-500 bg-gray-600 p-3"
            :class="modelValue == id ? '!bg-blue-700' : ''"
        >
            <div class="whitespace-nowrap text-center text-white">{{ label }}</div>
        </div>
    </label>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        id: { required: true },
        modelValue: { default: null },
        label: { type: String, required: true },
    },
    emits: ['update:modelValue'],
};
</script>
