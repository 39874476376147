<template>
    <a
        :href="goBackHref"
        class="rounded-full border border-gray-300 px-6 py-2 text-gray-300 transition-colors duration-300 hover:border-white hover:text-white"
    >
        <slot></slot>
    </a>
</template>

<script setup>
import { getQueryParametersWithout } from '../../../../utils/windowUtils';

const props = defineProps({
    href: { required: true },
    withoutQueryParams: { default: [] },
});

const goBackHref = `${props.href}?${getQueryParametersWithout(props.withoutQueryParams)}`;
</script>
