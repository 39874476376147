<template>
    <ConfigCard
        :badges="selection.device.badges"
        :href="href"
        class="w-full"
        data-spec-class="special-deals-card"
        :data-spec-id="`special-deals-card--${selection.device.id}`"
    >
        <div class="text-white">{{ selection.device.cpu.name }}</div>
        <div class="text-xs text-blue-600">
            {{ decorateCpuSpecs(selection.device.cpu) }}
        </div>

        <div class="text-white">
            <div>{{ selection.ram.name }}</div>
            <div v-for="gpuQuantity in selection.gpus">
                <template v-if="gpuQuantity.quantity > 1"> {{ gpuQuantity.quantity }}x </template>
                {{ gpuQuantity.gpu.name }}
            </div>
            <div v-for="storageQuantity in selection.storages">
                <template v-if="storageQuantity.quantity > 1">
                    {{ storageQuantity.quantity }}x
                </template>
                {{ storageQuantity.storage.name }}
            </div>
            <div>
                {{ selection.uplink.name }}
            </div>
        </div>

        <template #aside>
            <slot name="aside"> </slot>
        </template>
    </ConfigCard>
</template>

<script setup>
import { defineProps } from 'vue';
import ConfigCard from './Configurator/ConfigCard.vue';
import { useAssortmentDedicatedUtils } from '../composables/assortmentDedicated';

const { decorateCpuSpecs } = useAssortmentDedicatedUtils();

const props = defineProps({
    selection: {
        type: Object,
        required: true,
    },
});
</script>
