<template>
    <div
        data-spec-class="checkbox-quantity"
        :data-spec-id="name"
    >
        <input
            @input="$emit('update:modelValue', $event.target.value)"
            :value="modelValue"
            :name="name"
            type="number"
            class="hidden"
        />
        <div class="flex items-center space-x-6">
            <button
                :disabled="!canBeIncreased"
                :class="isActive ? 'bg-blue-700' : 'bg-gray-600'"
                class="flex-1 space-y-2 rounded border border-gray-500 px-3 py-2 text-center text-white disabled:cursor-not-allowed"
                @click.prevent="increase"
            >
                <slot />
            </button>
            <div class="flex items-center space-x-3 text-lg">
                <button
                    v-if="canBeDecreased"
                    class="bg-none text-xl text-white"
                    @click.prevent="decrease"
                    data-spec-class="checkbox-quantity__decrease"
                >
                    –
                </button>
                <span
                    v-else
                    class="text-xl text-gray-500"
                    >–</span
                >

                <span class="w-3 text-center text-white">{{ modelValue }}</span>
                <button
                    v-if="canBeIncreased"
                    class="bg-none text-xl text-white"
                    @click.prevent="increase"
                    data-spec-class="checkbox-quantity__increase"
                >
                    +
                </button>
                <span
                    v-else
                    class="text-xl text-gray-500"
                    >+</span
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        modelValue: { default: 0 },
        min: { type: Number, default: 0 },
        max: { type: Number, default: null },
        dataSpecId: { type: Number, default: null },
    },
    emits: ['decrease', 'increase'],
    computed: {
        isActive() {
            return this.modelValue !== 0;
        },
        canBeDecreased() {
            return this.modelValue > this.min;
        },
        canBeIncreased() {
            if (this.max === null) {
                return true;
            }
            return this.modelValue < this.max;
        },
    },
    methods: {
        decrease() {
            this.$emit('decrease');
        },
        increase() {
            this.$emit('increase');
        },
    },
};
</script>
