<template>
    <div
        class="mb-12 flex justify-center"
        data-spec-class="maintain-card"
    >
        <div class="flex flex-col items-center rounded bg-gray-700 p-6 md:w-1/2 md:px-24 md:py-12">
            <ExclamationTriangleIcon class="mb-1 w-32 text-blue-700"></ExclamationTriangleIcon>
            <div class="mb-2 text-xl font-bold text-blue-300">
                {{ t('globalMessages.configuratorMaintenance.message') }}
            </div>
            <div class="text-blue-700">
                {{ t('globalMessages.configuratorMaintenance.description') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
