<template>
    <div class="configurator mt-6">
        <div class="rounded bg-gray-700 py-6 text-white">
            <img
                src="/img/icons/23m/dedicated-server.svg"
                alt="Dedicated Server"
                class="mx-auto h-24 w-36"
            />
            <Columns>
                <Column>
                    <ColumnTitle>{{ $t('configuratorDedicated.base') }}</ColumnTitle>
                    <Radios>
                        <RadioBox
                            v-for="(device, i) in assortment.devices"
                            v-model="fields.deviceId"
                            :id="device.id"
                            :key="i"
                            :title="device.cpu.name"
                            :description="decorateCpuSpecs(device.cpu)"
                            :badges="device.badges"
                            name="device"
                        />
                    </Radios>
                </Column>

                <Column>
                    <ColumnTitle>RAM</ColumnTitle>
                    <Radios>
                        <template
                            v-for="(ram, i) in selection.device.components.ram[0].options"
                            :key="i"
                        >
                            <Radio
                                v-model.number="fields.ramCapacity"
                                :id="ram.capacity"
                                :label="ram.capacity + ' GB'"
                                name="ram"
                            />
                        </template>
                    </Radios>

                    <template
                        v-if="
                            selection.device?.components.gpu &&
                            selection.device?.components.gpu.length &&
                            selection.device?.components.gpu[0].options.length
                        "
                    >
                        <ColumnTitle class="pt-6">GPU</ColumnTitle>
                        <div class="flex flex-col space-y-6">
                            <RemainingSlots
                                v-if="selection.device?.components.gpu[0].size"
                                :amount="freeGpuSlots"
                            />

                            <template
                                v-for="(gpu, i) in selection.device?.components.gpu[0].options"
                                :key="i"
                            >
                                <CheckboxQuantity
                                    v-model="fields.gpuIds[gpu.id]"
                                    :name="'gpus[' + gpu.id + ']'"
                                    :min="0"
                                    :max="parseInt(fields.gpuIds[gpu.id] || 0) + freeGpuSlots"
                                    @increase="increaseGpu(gpu.id)"
                                    @decrease="decreaseGpu(gpu.id)"
                                >
                                    {{ gpu.name }}
                                </CheckboxQuantity>
                            </template>
                        </div>
                    </template>
                </Column>

                <Column>
                    <ColumnTitle>Storage</ColumnTitle>
                    <div class="flex flex-col space-y-6">
                        <RemainingSlots
                            v-if="selection.device?.components.storage[0].size"
                            :amount="freeStorageSlots"
                        />
                        <div
                            v-for="(storages, storageType) in storagesByType"
                            :key="storageType"
                        >
                            <CategoryTitle>{{ storageType }}</CategoryTitle>
                            <div class="ml-6 space-y-3">
                                <template
                                    v-for="(storage, i) in storages"
                                    :key="i"
                                >
                                    <CheckboxQuantity
                                        v-model="fields.storageIds[storage.id]"
                                        :name="'storages[' + storage.id + ']'"
                                        :min="0"
                                        :max="
                                            parseInt(fields.storageIds[storage.id] || 0) +
                                            freeStorageSlots
                                        "
                                        @increase="increaseStorage(storage.id)"
                                        @decrease="decreaseStorage(storage.id)"
                                    >
                                        {{ removeSubstring(storage.name, storageType) }}
                                    </CheckboxQuantity>
                                </template>
                            </div>
                        </div>
                    </div>
                </Column>

                <Column>
                    <ColumnTitle>{{ $t('configuratorGeneral.price') }}</ColumnTitle>
                    <PriceBanner
                        :price="monthlyPrice"
                        :setup-price="setupPrice"
                    />
                    <Price
                        :price="monthlyPrice"
                        :setup_price="setupPrice"
                    />
                    <div>
                        <DedicatedDeviceHintAvailability :device="selection.device" />
                        <hint>{{ $t('configuratorDedicated.contractTerm') }}</hint>
                        <hint>{{ $t('configuratorDedicated.noticePeriod') }}</hint>
                    </div>
                    <Confirm @click.prevent="goToNextPage()">{{
                        $t('configuratorGeneral.continue')
                    }}</Confirm>
                </Column>
            </Columns>

            <SpecialDealsAd></SpecialDealsAd>
        </div>
    </div>
</template>

<script setup>
import Columns from './Configurator/Columns.vue';
import Column from './Configurator/Column.vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Radios from './Configurator/Radios.vue';
import RadioBox from './Configurator/RadioBox.vue';
import Radio from './Configurator/Radio.vue';
import CategoryTitle from './Configurator/CategoryTitle.vue';
import CheckboxQuantity from './Configurator/CheckboxQuantity.vue';
import Price from './Configurator/Price.vue';
import PriceBanner from './Configurator/PriceBanner.vue';
import Confirm from './Configurator/Confirm.vue';
import RemainingSlots from './DedicatedConfigurator/RemainingSlots.vue';
import Hint from './Configurator/Hint.vue';
import SpecialDealsAd from './SpecialDealsAd.vue';
import { useAssortmentDedicated } from '../composables/assortmentDedicated';
import { removeSubstring } from '../../../utils/stringUtils';
import DedicatedDeviceHintAvailability from './DedicatedDeviceHintAvailability.vue';

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const {
    fields,
    assortment,
    monthlyPrice,
    setupPrice,
    selection,
    decorateCpuSpecs,
    freeStorageSlots,
    freeGpuSlots,
    storagesByType,
} = useAssortmentDedicated(
    props.assortmentContent,
    props.assortmentNetworkContent,
    props.assortmentIpContent
);

const increaseStorage = (id) => {
    if (freeStorageSlots < 1) {
        return;
    }
    if (fields.storageIds[id]) {
        fields.storageIds[id] += 1;
    } else {
        fields.storageIds[id] = 1;
    }
};

const decreaseStorage = (id) => {
    if (fields.storageIds[id] === undefined) {
        return;
    }
    if (fields.storageIds[id] === 1) {
        fields.storageIds[id] = 0;
    } else {
        fields.storageIds[id] -= 1;
    }
};

const increaseGpu = (id) => {
    if (freeStorageSlots < 1) {
        return;
    }
    if (fields.gpuIds[id]) {
        fields.gpuIds[id] += 1;
    } else {
        fields.gpuIds[id] = 1;
    }
};

const decreaseGpu = (id) => {
    if (fields.gpuIds[id] === undefined) {
        return;
    }
    if (fields.gpuIds[id] === 1) {
        fields.gpuIds[id] = 0;
    } else {
        fields.gpuIds[id] -= 1;
    }
};

const goToNextPage = () => {
    const url = '/dedicated-server/config/2';
    window.location.href = `${url}${window.location.search}`;
};
</script>
